import { WebAnalyticsHelper } from 'Helpers/WebAnalyticsHelper'

export const extramural = {
  /**
   * Клиент нажал кнопку "Дальше"
   * в заявке на заочное обучение
   * на шаге "Данные для договора"
   */
  stepContractDataButtonNextClicked() {
    WebAnalyticsHelper.goalDataContractExtramural()
  },

  /**
   * Клиент нажал кнопку "Дальше"
   * в заявке на заочное обучение
   * на шаге "Данные клиента"
   */
  stepClientDataButtonNextClicked() {
    WebAnalyticsHelper.goalDataClientExtramural()
  },

  /**
   * Клиент нажал кнопку "Дальше"
   * в заявке на заочное обучение
   * на шаге "Данные ученика"
   */
  stepStudentDataButtonNextClicked() {
    WebAnalyticsHelper.goalDataStudentExtramural()
  },
}
