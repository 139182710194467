import {
  helpers,
  email as vEmail,
  maxLength as vMaxLength,
  maxValue as vMaxValue,
  minLength as vMinLength,
  minValue as vMinValue,
  requiredUnless as vRequiredUnless,
  required as vRequred,
  requiredIf as vRequredIf,
  sameAs as vSameAs,
  url as vUrl,
} from '@vuelidate/validators'
import {
  emailErrorMessage,
  maxLengthErrorMessage,
  maxValueErrorMessage,
  minLengthErrorMessage,
  minValueErrorMessage,
  passworEqualsEmailErrorMessage,
  passwordsNotEqual,
  requiredErrorMessage,
  strongPasswordErrorMessage,
  urlErrorMessage,
  validateStrongPassword,
} from 'Helpers/ValidationHelper'

export const required = helpers.withMessage(requiredErrorMessage, vRequred)
export const requiredUnless = (value) =>
  helpers.withMessage(requiredErrorMessage, vRequiredUnless(value))

export const url = helpers.withMessage(urlErrorMessage, vUrl)

export const requiredIf = (value) =>
  helpers.withMessage(requiredErrorMessage, vRequredIf(value))

export const email = helpers.withMessage(emailErrorMessage, vEmail)
export const strongPassword = helpers.withMessage(
  strongPasswordErrorMessage,
  validateStrongPassword,
)

export const passwordSameAs = (value) =>
  helpers.withMessage(passwordsNotEqual, vSameAs(value))

export const sameAs = (value) =>
  helpers.withMessage(passworEqualsEmailErrorMessage, vSameAs(value))

export const minLength = (value) =>
  helpers.withMessage(minLengthErrorMessage(value), vMinLength(value))

export const maxLength = (value) =>
  helpers.withMessage(maxLengthErrorMessage(value), vMaxLength(value))

export const minValue = (value) =>
  helpers.withMessage(minValueErrorMessage(value), vMinValue(value))

export const maxValue = (value) =>
  helpers.withMessage(maxValueErrorMessage(value), vMaxValue(value))
