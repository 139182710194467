export const getLocaleMessages = function ({
  userType,
  institutionType,
  institutionId = 'default',
  lang = 'ru',
} = {}) {
  return import(
    `../../../node_modules/@ibls4/texts/src` +
      `/${institutionType}/${institutionId}` +
      `/${userType}/${lang}.json`
  )
    .then(({ default: messages = {} }) => messages)
    .catch(() => ({}))
}

export const getLocaleMessagesShowcase = function ({ lang = 'ru' }) {
  return import(`../../../node_modules/@ibls4/texts/src/showcase/${lang}.json`)
    .then(({ default: messages = {} }) => messages)
    .catch(() => ({}))
}
