import HttpStatusCodes from 'Api/const/HttpStatusCodes'
import DownloadException from 'Api/exceptions/Download'
import AxiosHelper from 'Helpers/AxiosHelper'

export default new (class extends AxiosHelper {
  constructor() {
    super()

    this.BASE_URL = `${window.domains.apiUrl}/download`
  }

  /**
   * @private
   * @param {Object} error
   * @throws {DownloadException}
   */
  _throwError(error) {
    if (!error.response) {
      // TODO: i18n
      throw new DownloadException({
        message: 'Ошибка скачивания файла',
      })
    }

    const { status } = error.response

    if (status === HttpStatusCodes.NotFound) {
      // TODO: i18n
      throw new DownloadException({
        message: 'Файл не найден',
        status,
      })
    }

    // TODO: i18n
    throw new DownloadException({
      message: 'Ошибка скачивания файла',
      status,
    })
  }

  /**
   * @param {number} id
   * @returns {null|string}
   */
  getDocumentUrl(id) {
    return id ? `${this.BASE_URL}/document/${id}` : null
  }

  /**
   * @param {number} id
   * @returns {null|string}
   */
  getFileUrl(id) {
    return id ? `${this.BASE_URL}/file/${id}` : null
  }

  getFileBlob(id) {
    const url = this.getFileUrl(id)
    const config = {
      responseType: 'blob',
    }

    return this.GET(url, config)
  }

  doesExistFile(path) {
    return this.HEAD(path).catch((error) => this._throwError(error))
  }
})()
