import ApiBase from 'ApiRest/ApiBase'
import UrlHelper from 'Helpers/UrlHelper'

export const ApiSupportQuestionInWorkCount = new (class extends ApiBase {
  /**
   * Не закрытые вопросы, назначенные данному сотруднику.
   * @param {Object} params
   * @returns {Promise}
   */
  get(params = {}) {
    const url = `/support/question/in-work-count?${UrlHelper.param(params)}`

    return this._GET(url)
  }
})()
